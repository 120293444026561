.productDetailInformationContainer {
  &__title {
    font-weight: 600;
    padding: 10px;
    background-color: $backgroundColorTitle;
    border-radius: 12px;
  }
  &__multiple {
    margin-bottom: 30px;
  }
}
