.settingCoffeeShopSuggestiveInformationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 50px;
  margin-bottom: 50px;
  height: auto;
  p {
    margin: 0 20px;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    min-width: 100px;
    display: flex;
    word-wrap: break-word;
    margin-left: 10px;
    width: 120px;
  }
  &__description {
    width: 50%;
    margin-left: 30px;
  }
  @include breakpoints(md) {
    &__description {
      width: 800px;
      height: auto;
    }
  }
}
.quill {
  min-width: 200px;
  border-radius: 10px;
  border: 2px solid rgb(177, 171, 171);
  overflow: auto;
}
