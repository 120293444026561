.modal {
  position: absolute;
  overflow: unset;
  top: 60px;
  right: 15px;
  width: 343px;
  // height: 539px;
  height: auto;
  max-height: 539px;
  background-color: white;
  border-radius: 14px;
  box-shadow: 0px 16px 80px rgba(4, 15, 21, 0.15),
    0px 40px 20px -30px rgba(4, 15, 21, 0.1);
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 100;
  &__content {
    position: relative;
    &__header {
      display: flex;
      align-items: center;
      padding: 20px 0;
      margin: 0px;
      &__title {
        margin-left: 20px;
        font-size: 20px;
        font-weight: 700;
        color: #637085;
      }
      &__count {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background-color: #ff6640;
        position: relative;
        margin-left: 120px;
        p {
          position: absolute;
          font-size: 11px;
          font-weight: 400;
          font-family: $font-primary;
          color: white;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    &__separate {
      hr {
        width: 90%;
        border: none;
        height: 1px;
        background-color: #e5e8ea;
        margin-bottom: 10px;
      }
    }
    &__body {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 0;
      width: 343px;
      height: 56px;
      text-align: left;
      &__item {
        display: flex;
        align-items: center;
        column-gap: 15px;
        padding: 20px 0px;
        width: 220px;
        &__icon {
          width: 50px;
          img {
          }
        }
        &__description {
          h3 {
            width: 172.9px;
            font-size: 14px;
            font-weight: 700;
            color: #637085;
          }
          &__text {
            display: block;
            width: 172.9px;
            padding: 3px 0px;
            font-size: 12px;
            font-weight: 400;
            color: #637085;
          }
          &__container {
            display: flex;
            align-items: center;
            width: 190px;
            &__name {
              width: 100px;
              margin-left: 9px;
              padding: 3px 0px;
              font-size: 12px;
              font-weight: 400;
              color: #637085;
              overflow: hidden;
            }
            &__time {
              width: 90px;
              margin-left: 0px;
              padding: 3px 0px;
              font-size: 12px;
              font-weight: 400;
              color: #637085;
            }
          }
        }
      }
      &__aspa {
        display: flex;
        grid-template-columns: 1fr;
        margin: auto;
        justify-content: center;
        align-items: center;
        p {
          color: black;
          font-size: 25px;
        }
      }
    }
    &__body:hover {
      background-color: #f5f7f9;
      // background-color: #36485a;
      border-radius: 14px;
    }
  }
}

.hidden-notification {
  display: none;
}

.modal::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.modal::-webkit-scrollbar-thumb {
  background: #637085;
  border-radius: 25px;
}
