.paymentListFieldsValueComponent {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    text-align: center;
    &__code {
      width: 100px;
    }
    &__order {
      width: 100px;
    }
    &__method {
      width: 150px;
    }
    &__state {
        width: 110px;
    }
    &__date {
        width: 300px;
      }
  }
  