.orderDetailMainContainer {
  margin: 20px;
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  @include breakpoints(lg) {
    display: grid;
    grid-template-columns: 1fr 400px;
  }
}
