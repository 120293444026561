.multipleSelectChip {
  margin: auto 20px;
}
.css-13cymwt-control {
  border-radius: 14px !important;
  transition: all 0.3s ease-out;
  border: 1px solid #ebeef2;
  &:hover {
    border: 1.5px solid rgba(45, 153, 255, 1);
  }
}
