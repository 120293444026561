.paymentRetrieveVoucherContainer {
  padding: 10px 30px;
  @include breakpoints(lg) {
    width: 360px;
    padding: 10px 25px;
  }
  &__image {
    padding: 10px 0px;
    img {
      border-radius: 12px;
    }
  }
  &__title {
    font-weight: 600;
    padding: 10px 16px;
    background-color: $backgroundColor;
    border-radius: 12px;
  }
}
