.orderDetailPaymentContainer {
  padding: 10px 25px;

  &__title {
    font-weight: 600;
    padding: 10px 16px;
    background-color: $backgroundColor;
    border-radius: 12px;
  }
  &__fields {
    width: 92%;
    p {
      margin: 0;
    }
  }
  &__button {
    p {
        font-size: 14px;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
