.orderDetailUserDataContainer {
  padding: 0 10px;
  &__title {
    font-weight: 600;
    padding: 10px 16px;
    background-color: #f5f7fa;
    border-radius: 12px;
  }
  &__container {
    padding: 20px;
    &__description {
      p {
        padding: 5px 0;
      }
    }
  }
}
