.clientRetrieveAddressFieldsKeyComponent {
  height: 40px;
  background-color: $backgroundColor;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 15px;
  &__address {
    width: 400px;
  }
  &__reference {
    width: 150px;
  }
  &__latitude {
    width: 180px;
  }
  &__longitude {
    width: 180px;
  }
  &__first {
    width: 120px;
  }
  &__map {
    width: 100px;
  }
}
