.main-container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 80px 1fr;
  height: 100vh;
  overflow: hidden;
  position: relative;
  &__sidebar {
    height: 100vh;
  }

  &__topnav {
    border: 3px solid #eaecef;
    background-color: #f2f4f7;
    width: 100%;
    height: 100%;
    grid-column: 2/3;
    grid-row: 1/2;
    overflow: hidden;
  }
  &__outlet {
    border: 3px solid #eaecef;
    background-color: #f2f4f7;
    width: 100%;
    margin: auto;
    height: 100%;
    grid-column: 2/3;
    grid-row: 2/3;
    overflow-x: hidden;
  }
  @include breakpoints(sm) {
  }
  @include breakpoints(md) {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 80px 1fr;
    height: 100vh;
    overflow: hidden;
    position: relative;
    &__sidebar {
      border: 3px solid #eaecef;
      background-color: #f2f4f7;
      grid-column: 1/2;
      grid-row: 1/3;
      width: 100%;
      height: 100%;
    }
    &__sidebar-hidden {
      position: absolute;
      margin-left: 273px;
      height: 100vh;
      background-color: #f2f4f7;
      transform: translateX(-540px);
    }

    &__topnav {
      border: 3px solid #eaecef;
      background-color: #f2f4f7;
      width: 100%;
      height: 100%;
      grid-column: 2/3;
      grid-row: 1/2;
    }
    &__outlet {
      border: 3px solid #eaecef;
      background-color: #f2f4f7;
      height: 100%;
      grid-column: 2/3;
      grid-row: 2/3;
    }
  }
}
