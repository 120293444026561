.storeDetailTableBodyContainer {
  input {
    outline: none;
    width: 70%;
    text-align: center;
    border-radius: 14px;
    border: 1px solid #ebeef2;
    padding: 11px 5px;
    outline: none;
    color: hsl(0, 0%, 20%);
    font-family: "Nunito", serif;
    font-size: 14px;
  }
  height: 70px;
  &__name {
    text-align: center;
    min-width: 300px;
    max-width: 300px;
  }
  &__image {
    min-width: 500px;
    max-width: 500px;
    &__link {
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      a {
        color: blue;
        width: 100%;
      }
    }
  }
  &__order {
    text-align: center;
    min-width: 200px;
    max-width: 200px;
    input {
      text-align: center;
    }
  }
  &__active {
    text-align: center;
    min-width: 100px;
    max-width: 100px;
  }
}
