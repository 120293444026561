.settingBoxComponent {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0px 0px 5px 2px #d9d9d9;
  margin-bottom: 20px;
  column-gap: 30px;
  min-width: 200px;
  cursor: pointer;
  //   &__icon {
  //     img {
  //     }
  //   }
  &__description {
    width: 400px;

    &__title {
      padding-bottom: 10px;
    }
    &__text {
      line-height: 17px;
    }
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 60px;
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0.39);
    border-radius: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  }
  @include breakpoints(sm) {
    width: 500px;
  }
  @include breakpoints(md) {
  }
  @include breakpoints(lg) {
    width: 400px;
  }
  @include breakpoints(xl) {
    width: 500px;
  }
}
.settingBoxComponent:hover {
  background-color: #f2f2f2;
  box-shadow: 0px 0px 10px 4px #d9d9d9;
}
