.reportTableHeadComponent {
  border-collapse: collapse;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: #fff;
  color: #212529;
  font-size: 1rem;
  line-height: 1.5;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  &__head {
    // vertical-align: middle;
    &__row {
      // vertical-align: middle;
      background-color: #e9ecef;
      &__text {
        padding: 0.75rem;
        vertical-align: middle;
        border: 1px solid black;
        min-width: 100px;
      }
      &__text.title-empty {
        border: none;
      }
    }
  }
  &__body {
    &__row {
      &__text {
        height: auto;
        border: 1px solid black;
        text-align: center;
        padding: 0.75rem;
        vertical-align: top;
      }
      &__text.reportWith {
        min-width: 300px;
      }
    }
  }
}


