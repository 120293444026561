.sectionSelectionContainer {
  min-width: 350px;
  &__title {
    padding: 10px 16px;
    background-color: #f5f7fa;
    border-radius: 12px;
    font-weight: 600;
  }
  &__container {
    padding: 20px 40px;
    &__header {
      display: grid;
      grid-template-columns: 1fr 110px;
      padding: 10px 0;
    }
    &__fields {
      display: flex;
      padding: 10px 0;
      &__input {
        border: 1px solid #ebeef2;
        border-radius: 14px;
        transition: all 0.3s ease-out;
        overflow: hidden;
        width: 100%;
        select {
          width: 100%;
          height: 40px;
          border-radius: 14px;
          transition: all 0.3s ease-out;
          border: none;
          outline: none;
          overflow: hidden;
          padding: 3px 5px;
          option {
          }
        }
      }
      &__checked {
        width: 120px;
        text-align: center;
        align-self: center;
        input {
        }
      }
    }
  }
}
