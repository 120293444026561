.informationFieldInputContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  &__text {
    width: 100%;
    max-width: 100px;
  }
  &__input {
    border: 1px solid #ebeef2;
    border-radius: 14px;
    transition: all 0.3s ease-out;
    overflow: hidden;
    width: 100%;
    input,
    textarea {
      width: 100%;
      height: 40px;
      border-radius: 14px;
      transition: all 0.3s ease-out;
      border: none;
      outline: none;
      overflow: hidden;
      padding: 3px 5px;
    }
  }
  &__input-number,
  &__input-checked {
    width: 100%;
    input {
      border: 1px solid #ebeef2;
      min-width: 20px;
      max-width: 100px;
      text-align: center;
      height: 40px;
      border-radius: 14px;
      transition: all 0.3s ease-out;
      outline: none;
      padding: 3px 5px;
    }
  }
}
