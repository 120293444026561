.modalMapContainer {
  background-color: white;
  border-radius: 16px;
  padding: 20px;

  &__map {
    height: 50vh;
    width: 75vw;
    @include breakpoints(lg) {
      width: 50vw;
    }
  }
  &__close {
    display: flex;
    padding-bottom: 10px;
    align-items: center;
    p {
      font-size: 24px;
      font-weight: bold;
      color: $colorButton;
    }
    button {
      border: none;
      background-color: $colorWhite;
      cursor: pointer;
      font-size: 25px;
      font-weight: bold;
      max-width: 30px;
      margin: 0;
    }
  }
  &__address {
    margin: 20px auto 0 auto;
    max-width: 50vw;
  }
}
