.homeContainer {
  // padding: 20px 0 20px 40px;
  margin: 20px;
  h2 {
    padding-bottom: 20px;
  }
  &__buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 30px;
    width: 100%;
    gap: 10px;
    flex-wrap: wrap;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $colorButton;
      cursor: pointer;
      border: none;
      text-align: center;
      border-radius: 14px;
      column-gap: 20px;
      width: 291px;
      height: 51px;
      p {
        font-family: "Nunito";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
        transition: all 0.3s ease-in-out;
        transform: scale(1);
      }
    }
    a:hover {
      background-color: white;
      transition: all 0.3s ease-in-out;

      transform: scale(1.05);
      p {
        color: $colorButton;
      }
    }
  }

  &__dashboard {
    &__container {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;
      width: 100%;

      // margin: 0 20px;
      &__figure {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding-bottom: 20px;
        gap: 20px;
        width: 100%;
        &__loading {
          position: relative;
          margin-top: -30px;
        }
        &__bar {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          border-radius: 16px;
          padding-top: 10px;
          width: 507px;
          // height: 498px;
          height: 300px;
          background: #ffffff;
          box-shadow: 0px 20px 30px -18px rgba(4, 15, 21, 0.1);
          text-align: center;
          canvas {
            width: 97%;
            // height: 200px;
            border: 1.5px solid #e3e5e8;
            border-radius: 16px;
            // padding: 10px;
          }
        }
        &__doughnut {
          border-radius: 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          width: 410px;
          padding-top: 10px;
          // height: 498px;
          height: 350px;
          background: #ffffff;
          box-shadow: 0px 20px 30px -18px rgba(4, 15, 21, 0.1);
          canvas {
            width: 250px;
            height: 250px;
            border: 1.5px solid #e3e5e8;
            border-radius: 16px;
            padding: 10px;
          }
        }
      }
      &__line {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        border-radius: 16px;
        background: #ffffff;
        box-shadow: 0px 20px 30px -18px rgba(4, 15, 21, 0.1);
        width: 100%;
        height: 400px;
        margin-bottom: 20px;
        &__loading {
          position: relative;
          width: 100%;
          height: 100%;
        }

        &__container {
          text-align: center;
          margin-top: -30px;

          &__filters {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-wrap: wrap;
            gap: 20px;
          }
          &__button {
            padding: 0 10px;
            height: 48px;
            border-radius: 5px;
            background: $colorButton;
            border: 1px solid $colorWhite;
            font-size: 15px;
            font-weight: 600;
            box-shadow: 0px 4px 16px rgba(0, 129, 255, 0.18);
            border-radius: 14px;
            color: $colorWhite;
            cursor: pointer;
          }
        }
        canvas {
          border: 1.5px solid #e3e5e8;
          border-radius: 16px;
          padding: 10px;
          width: 100%;
        }
      }
    }
  }
  @include breakpoints(sm) {
    &__dashboard {
      &__container {
        &__figure {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 20px;
          gap: 20px;
          width: 100%;

          &__bar {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            padding: 30px 0 20px 0;
            border-radius: 16px;
            row-gap: 10px;
            // width: 507px;
            // height: 498px;
            // height: 350px;
            background: #ffffff;
            box-shadow: 0px 20px 30px -18px rgba(4, 15, 21, 0.1);
            canvas {
              // width: 447.64px;
              width: 98%;
              // height: 231px;
              border: 1.5px solid #e3e5e8;
              border-radius: 16px;
              padding: 10px;
            }
          }
          &__doughnut {
            border-radius: 16px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            // width: 410px;
            // height: 498px;
            // height: 350px;
            width: 507px;
            height: 350px;
            background: #ffffff;
            box-shadow: 0px 20px 30px -18px rgba(4, 15, 21, 0.1);
            canvas {
              width: 250px;
              height: 250px;
              border: 1.5px solid #e3e5e8;
              border-radius: 16px;
              padding: 10px;
            }
          }
        }
        &__line {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          border-radius: 16px;
          background: #ffffff;
          box-shadow: 0px 20px 30px -18px rgba(4, 15, 21, 0.1);
          width: 88%;
          height: 400px;
          margin-bottom: 20px;
          &__filters {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-wrap: wrap;
            gap: 20px;
          }
          canvas {
            border: 1.5px solid #e3e5e8;
            border-radius: 16px;
            padding: 10px;
            width: 120%;
          }
        }
      }
    }
  }
  @include breakpoints(md) {
    &__dashboard {
      &__container {
        &__figure {
          &__bar {
            canvas {
              width: 98%;
            }
          }
        }
        &__line {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          flex-wrap: wrap;
          border-radius: 16px;
          background: #ffffff;
          box-shadow: 0px 20px 30px -18px rgba(4, 15, 21, 0.1);
          width: 80%;
          height: 400px;
          margin-bottom: 20px;
          &__container {
            margin-top: -10px;
          }
          &__filters {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-wrap: wrap;
            gap: 20px;
          }
          canvas {
            border: 1.5px solid #e3e5e8;
            border-radius: 16px;
            padding: 10px;
            width: 500px;
            height: 250px;
          }
        }
      }
    }
  }
  @include breakpoints(lg) {
    &__dashboard {
      &__container {
        &__line {
          width: 99%;
          // width: 1040px;
          height: 500px;
          margin-bottom: 20px;

          &__container {
            text-align: center;
            display: flex;
            align-items: center;
            column-gap: 20px;
            flex-wrap: wrap;
          }
          canvas {
            width: 600px;
            height: 300px;
          }
        }
      }
    }
  }
  @include breakpoints(xl) {
    &__dashboard {
      &__container {
        &__line {
          width: 92%;
          // height: 620px;
          canvas {
            border: 1.5px solid #e3e5e8;
            border-radius: 16px;
            padding: 10px;

            width: 122%;
            // height: 400px;
          }
        }
      }
    }
  }
}

.rmdp-container {
  input {
    width: 98%;
  }
}
