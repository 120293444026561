.orderDetailUserCustomerContainer {
  padding: 10px;
  width: 95%;
  &__title {
    font-weight: 600;
    padding: 10px 16px;
    background-color: #f5f7fa;
    border-radius: 12px;
  }
  &__container {
    padding: 20px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    &__image {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 40px;
      }
    }
    &__description {
      width: 100%;

      &__text {
        font-weight: 600;
        font-size: 14px;
      }
      &__email {
        font-size: 13px;
        color: #637085;
      }
    }
  }
}
