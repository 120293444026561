.productComplementListFieldsValueComponent {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 15px;
  &__checkbox {
    width: 20px;
  }
  &__products {
    width: 700px;
  }
  &__fullName {
    width: 200px;
  }
  &__phone {
    width: 100px;
  }
}
