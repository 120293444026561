.productDetailGroupNoteContainer {
  &__container {
    margin: 20px 0;
    &__title {
      font-weight: 600;
      padding: 10px;
      background-color: $backgroundColorTitle;
      border-radius: 12px;
    }
    &__table {
      overflow-x: auto;
    }
    &__left {
      margin-left: 10%;
      &__title {
        font-weight: 600;
        padding: 10px;
        background-color: $backgroundColorTitle;
        border-radius: 12px;
      }
      &__table {
        overflow-x: auto;
      }
    }
  }
}
