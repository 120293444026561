.top-nav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: calc(100% - 1fr);
  height: 100%;
  &__open-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    min-width: 100px;
    margin: auto;

    &__search {
      display: flex;
      justify-content: center;
      align-items: center;
      &__svg {
        display: flex;
        order: 2;
      }
      &__input {
        width: 100%;
        input {
          width: 50%;
          border: transparent;
          background-color: transparent;
        }
        input:focus {
          outline: none;
          overflow: hidden;
        }
        input::placeholder {
          color: transparent;
        }
      }
    }
  }
  &__container-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-right: 10px;
    &__separate {
      width: 1px;
      height: 31px;
      background-color: #e3e5e8;
    }
    &__notification {
      display: flex;
      cursor: pointer;
      svg {
        min-width: 22px;
        height: 22px;
      }
    }
    &__icon {
      display: flex;
      cursor: pointer;
    }
  }
  @include breakpoints(sm) {
    column-gap: 20%;
  }
  @include breakpoints(md) {
    width: 100%;

    &__open-menu {
      width: 60%;
      &__svg {
        cursor: pointer;
        svg {
          min-width: 28px;
          height: 22px;
          width: 100%;
        }
      }
      &__search {
        &__svg {
          order: 0;
          display: flex;
          svg {
            min-width: 22px;
            height: 22px;
            width: 100%;
          }
        }
        &__input {
          width: 100%;
          input {
            width: 100%;

            border: transparent;
            background-color: transparent;
          }
          input:focus {
            outline: none;
          }
          input::placeholder {
            color: #637085;
          }
        }
      }
    }

    &__container-right {
      margin-right: 40px;
      &__separate {
        width: 1px;
        height: 31px;
        background-color: #e3e5e8;
      }
    }
  }
  @include breakpoints(lg) {
    width: 100%;
    column-gap: 50%;

    &__search {
      input {
        display: flex;
        border: none;
        outline: none;
      }
    }
    &__container-right {
      gap: 30px;
    }
  }
  @include breakpoints(xlg) {
  }
  @include breakpoints(xxlg) {
  }
}
