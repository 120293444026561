.tableListFieldsKeyComponent {
  width: 100%;
  background-color: $backgroundColor;
  display: flex;
  align-items: center;
  font-size: 15px;
  height: 40px;
  &__checkbox {
    width: 100px;
  }
  &__code {
    width: 100px;
  }
  &__name {
    width: 200px;
  }
  &__district {
    width: 200px;
  }
  &__created {
    width: 300px;
  }
  &__order {
    width: 200px;
  }
  &__fullName {
    width: 200px;
  }
  &__district {
    width: 200px;
  }
  &__typeContracted {
    width: 200px;
  }
  &__typeClaim {
    width: 200px;
  }
  &__fullName {
    width: 200px;
  }
  &__document {
    width: 200px;
  }
  &__typeDocument {
    width: 200px;
  }
  &__numberDocument {
    width: 200px;
  }
  &__shop {
    width: 200px;
  }
  &__key {
    min-width: 160px;
  }
  &__value {
    min-width: 500px;
  }
  &__email {
    width: 300px;
  }
  &__state {
    width: 150px;
  }
  &__type-form {
    width: 300px;
  }
  &__description {
    width: 300px;
  }
  &__numberPerson {
    width: 100px;
  }
  &__reservationReason {
    width: 200px;
  }

  &__phone {
    width: 200px;
  }
  &__time {
    width: 200px;
  }
  &__date {
    width: 200px;
  }
  &__active {
    width: 80px;
  }
}
