.productCollectionDetailInformationContainer {
  min-width: 350px;
  &__fields {
    padding: 20px 40px;
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
      &__text {
        width: 100%;
        max-width: 100px;
      }
      &__input-date {
        display: flex;
        flex-direction: column;
        width: 100%;
        &__input {
          width: 100%;
          border: 1px solid #ebeef2;
          border-radius: 14px;
          transition: all 0.3s ease-out;
          overflow: hidden;
          input {
            height: 40px;
            border-radius: 14px;
            border: none;
            outline: none;
            overflow: hidden;
            padding: 3px 5px;
          }
        }
        &__container {
          display: flex;
          &__text {
            width: 60px;
            p {
              width: 100%;
              cursor: pointer;
            }
          }
          &__icon {
            cursor: pointer;
            position: relative;
            &__modal {
              position: absolute;
              top: -20px;
              left: 20px;
              width: 120px;
              height: auto;
              text-align: center;
              box-shadow: 0px 4px 14px -3px rgba(0, 0, 0, 0.75);

              &__title {
                font-weight: 900;
                padding: 5px;
                background-color: #f5dd5d;
              }
              &__items {
                background-color: white;
                &__item {
                  color: #4b7bff;
                  padding: 5px;
                }
              }
              &__button {
                padding: 5px;
                height: 23px;
                background-color: gray;
              }
            }
          }
        }
      }
    }
  }
}
