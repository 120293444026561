.tableListFieldsValueComponent {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 15px;
  &__checkbox {
    width: 100px;
  }
  &__code {
    width: 100px;
  }
  &__state {
    width: 150px;
  }
  &__name {
    width: 200px;
  }
  &__created {
    width: 300px;
  }
  &__fullName {
    width: 200px;
  }
  &__district {
    width: 200px;
  }
  &__typeContracted {
    width: 200px;
  }
  &__order {
    width: 200px;
  }
  &__typeClaim {
    width: 200px;
  }
  &__typeDocument {
    width: 200px;
  }
  &__numberDocument {
    width: 200px;
  }
  &__shop {
    width: 200px;
  }
  &__email {
    width: 300px;
  }
  &__document {
    width: 200px;
  }
  &__key {
    text-align: left;
    min-width: 160px;
  }
  &__value {
    text-align: left;
    min-width: 500px;
  }
  &__description {
    width: 300px;
    text-align: left;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  &__type-form {
    min-width: 300px;
  }
  &__phone {
    width: 200px;
  }
  &__date {
    width: 200px;
  }
  &__time {
    width: 200px;
  }
  &__numberPerson {
    width: 100px;
  }
  &__reservationReason {
    width: 200px;
  }
  &__active {
    width: 80px;
  }
}
