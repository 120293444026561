.orderDetailUserModalityContainer {
  padding: 10px;
  width: 100%;
  &__title {
    font-weight: 600;
    padding: 10px 0px;
    background-color: #f5f7fa;
    border-radius: 12px;
  }
  &__container {
    padding: 20px 0;
    width: 95%;
    &__description {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      &__left {
        text-align: left;
        width: 100%;
      }
      &__right {
        width: 100%;
        text-align: right;
      }
      &__right.sizeHour {
        font-size: 13px;
        font-weight: 600;
      }
    }
  }
  &__map {
    margin: 50px 0;
    height: 65vh;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  @include breakpoints(sm) {
  }
  @include breakpoints(md) {
  }
  @include breakpoints(lg) {
    width: 350px;
    &__container {
      padding: 20px;
      width: 280px;
      margin-left: 0px;

      &__description {
        margin: 0px;
        padding: 10px 0;
        display: flex;
        &__left {
          width: 100%;
          text-align: left;
        }
        &__right {
          width: 280px;
          text-align: right;
        }
      }
    }
  }
}
