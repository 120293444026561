.orderDetailItemContainer {
  padding: 0 25px;
  max-width: 100%;
  &__title {
    padding: 10px 16px;
    background-color: #f5f7fa;
    border-radius: 12px;
    font-weight: 600;
  }
  &__container {
    padding: 10px;
    &__product {
      padding: 10px;
      display: flex;
      align-items: center;
      &__description {
        width: 100%;
        display: flex;
        align-items: center;
        // justify-content: flex-start;
        &__image {
          background-color: #fff;
          width: 120px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 14px;
          border: 1px solid #ebeef2;
          overflow:hidden ;
          img {
            object-fit: cover;
            height: 60px;
          }
        }
        &__text {
          width: 100%;
          padding: 0px 10px;
          h6 {
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: #1a1926;
          }
          p {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: #637085;
          }
        }
      }
      &__price {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        row-gap: 3px;
        p {
          width: 70px;
          font-size: 14px;
        }
        button {
          margin-left: 10px;
          border: none;
          background-color: $colorButton;
          color: $colorWhite;
          border-radius: 50%;
          width: 25px;
          height: 25px;
          cursor: pointer;
          svg {
            display: flex;
            justify-content: center;
            align-items: center;
            fill: white;
          }
        }
      }
      &__item {
        width: auto;
        font-size: 13px;
        &__title {
          font-size: 13px;
          padding: 10px 16px;
          background-color: #f5f7fa;
          border-radius: 12px;
          font-weight: 300;
          width: auto;
        }
        &__comment {
          padding: 10px 20px 20px 40px;
          // background-color: red;
          font-size: 13px;
        }
        &__description {
          display: flex;

          &__text {
            min-width: 100px;
            padding: 10px 0;
          }
          &__amount {
            align-self: center;
            width: 70px;
          }
        }
      }
    }
  }

  @include breakpoints(sm) {
    &__container {
      &__product {
        &__price {
          flex-direction: row;
        }
      }
    }
  }
}
