.clientRetrieveOrderFieldsKeyComponent {
  height: 40px;
  background-color: $backgroundColor;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 15px;
  &__code {
    width: 80px;
  }
  &__modality {
    width: 100px;
  }
  &__shop {
    width: 100px;
  }
  &__total {
    width: 80px;
  }
  &__state {
    width: 80px;
  }
  &__date {
    width: 300px;
  }
}
