.noteCreateHeaderContainer {
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__title {
    display: flex;
    align-items: center;
    margin: 0 auto 0 20px;
  }
  &__container-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px 0 auto;
    gap: 20px;
  }
  @include breakpoints(md) {
    &__container-button {
      flex-direction: row;
    }
  }
}
