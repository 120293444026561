.productDetailGroupModifiedContainer {
  &__container {
    margin: 20px 0;
    &__title {
      font-weight: 600;
      padding: 10px;
      background-color: $backgroundColorTitle;
      border-radius: 12px;
    }
    &__table {
      overflow-x: auto;
    }
    &__left {
      margin-left: 10%;
      padding: 10px 0;
      &__title {
        font-weight: 600;
        padding: 10px;
        background-color: $backgroundColorTitle;
        border-radius: 12px;
      }
      &__description {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        outline: none;
        &__select {
          width: 100%;
          height: 40px;

          select {
            border: 1px solid #ebeef2;
            border-radius: 14px;
            outline: none;
            width: 80%;
            height: 100%;
          }
        }
      }
      &__button {
        font-weight: 600;
        padding: 10px;
        margin: 10px;
        background-color: $backgroundColorTitle;
        text-align: end;
        cursor: pointer;
        transition: background-color 0.5s ease-in-out;
      }
      &__button:hover {
        background-color: #a3a4a6;
      }
    }
  }
}
