.storeCoverageDetailInformationContainer {
  width: 100%;
  height: 40px;
  background-color: $backgroundColor;
  display: flex;
  align-items: center;
  font-size: 15px;
  &__lat {
    width: 200px;
  }
  &__long {
    width: 200px;
  }
  &__active {
    width: 200px;
  }
}

.storeCoverageDetailInformationContainerTable {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  font-size: 15px;
  text-align: center;
  input {
    margin: 0 auto;
    border-radius: 14px;
    border: 1px solid #ebeef2;
    padding: 11px 15px;
    outline: none;
    color: hsl(0, 0%, 20%);
    font-family: "Nunito", serif;
    font-size: 16px;
    width: 150px;
  }
  input:focus {
    border: 2px solid #4d99fd;
  }
  input:hover {
    border: 1px solid #b3b3b3;
  }
  &__lat {
    width: 200px;
  }
  &__long {
    width: 200px;
  }
  &__active {
    width: 200px;
  }
}
