.productDetailGroupPresentationContainer {
  &__container {
    margin: 20px 0;
    &__title {
      font-weight: 600;
      padding: 10px;
      background-color: $backgroundColorTitle;
      border-radius: 12px;
    }
    &__table {
      overflow-x: auto;
      width: 100%;
    }
    &__left {
      padding: 10px 0;
      margin-left: 10%;

      &__title {
        font-weight: 600;
        padding: 10px;
        background-color: $backgroundColorTitle;
        border-radius: 12px;
      }
      &__table {
        overflow-x: auto;
        &__button {
          width: 100%;
          text-align: right;
          background-color: $backgroundColorTitle;
          cursor: pointer;
          transition: background-color 0.5s ease-in;
        }
        &__button:hover {
          background-color: #c1c1c1;
        }
      }
    }
  }
}
