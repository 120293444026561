.ModalGetHourComponent {
  position: absolute;
  top: -20px;
  left: 20px;
  width: 120px;
  height: auto;
  text-align: center;
  box-shadow: 0px 4px 14px -3px rgba(0, 0, 0, 0.75);
  &__title {
    font-weight: 900;
    padding: 5px;
    background-color: #f5dd5d;
  }
  &__items {
    background-color: white;
    &__item {
      color: #4b7bff;
      padding: 5px;
    }
  }
  &__button {
    padding: 5px;
    height: 23px;
    background-color: gray;
  }
}
