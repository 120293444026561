.login-form {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  &__container {
    width: 100%;
    min-width: 375px;
    max-width: 400px;
    justify-content: space-between;
    box-shadow: 0px 4px 14px -3px rgba(0, 0, 0, 0.75);
    &__welcome {
      background-image: url("https://d302y7dk73tjzt.cloudfront.net/media/landing/background/photo/cover.webp");
      margin-bottom: 50px;
      background-repeat: no-repeat;
      background-position: 25% 55%;
      background-size: cover;
      height: 230px;
      width: 100%;
      padding-bottom: 50px;
    }
    &__form {
      background-color: $colorWhite;
      height: calc(100vh - 215px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: -115px;
      border-radius: 16px 16px 0px 0px;
      &__title {
        font-family: $font-primary;
        padding-bottom: 50px;
        font-size: 1.5rem;
        font-weight: 800;
      }
      &__main {
        &__error {
          height: 15px;
          p {
            font-size: 10px;
            color: red;
          }
        }
        &__label {
          margin-bottom: 10px;
          font-size: 1rem;
          font-weight: 600;
          &__title {
            font-family: $font-primary;
            padding-bottom: 10px;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
          }
          // @include breakpoints(xxl) {
          //   font-size: 3rem;
          //   &__title{
          //     font-size: 2rem;
          //   }
          // }
        }

        &__input {
          width: 90%;
          input {
            width: 300px;
            border: 1px solid $colorBlack;
            border-radius: 5px;
            height: 30px;
            padding: 10px;
            outline: none;
            margin-bottom: 15px;
          }
          // @include breakpoints(xxl) {
          //   width: 100%;
          //   input{
          //     font-size: 2rem;
          //     width: 500px;
          //     height: 70px;
          //   }
          // }
        }
        &__button {
          width: 100%;
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          button {
            width: 343px;
            height: 48px;
            border-radius: 5px;
            background: $colorButton;
            border: 1px solid $colorWhite;
            font-size: 15px;
            font-weight: 600;
            box-shadow: 0px 4px 16px rgba(0, 129, 255, 0.18);
            border-radius: 14px;
            color: $colorWhite;
            cursor: pointer;
          }
          // @include breakpoints(xxl) {
          //   margin-top: 50px;
          //   button{
          //     width: 530px;
          //     height: 70px;
          //     font-size: 2rem;
          //   }
          // }
        }
      }
    }
  }
}
