.listHeaderContainer {
  margin: 20px auto;
  &__content {
    display: flex;
    &__title {
      display: flex;
      align-items: center;
      margin: 0 auto 0 20px;
    }
    &__button {
      display: flex;
      align-items: center;
      margin: 0 20px 0 auto;
      display: flex;
      justify-content: center;
      align-self: center;
      row-gap: 10px;
      column-gap: 20px;
      flex-wrap: wrap;
    }
  }
}
