.comboDetailGroupsContainer {
  overflow-x: scroll;
  height: auto;

  &__table {
    margin-top: 10px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    // min-width: 1200px;
    width: 100%;

    &__header {
      &__row {
        display: flex;
        text-align: center;
        margin-bottom: 10px;
        &__name {
          width: 300px;
          margin: auto 5px;
        }
        &__products {
          width: 500px;
          margin: auto 5px;
        }
        &__quantity {
          width: 100px;
          margin: auto 5px;
        }
        &__order {
          width: 100px;
          margin: auto 5px;
        }
        &__id {
          width: 100px;
          margin: auto 5px;
        }
        &__delete {
          width: 100px;
          margin: auto 5px;
        }
      }
    }
    &__body {
      text-align: center;

      &__row {
        display: flex;
        text-align: center;
        margin-bottom: 20px;
        input {
          outline: none;
          width: 90%;
          text-align: center;
          border-radius: 14px;
          border: 1px solid #ebeef2;
          padding: 11px 5px;
          outline: none;
          color: hsl(0, 0%, 20%);
          font-family: "Nunito", serif;
          font-size: 14px;
        }

        &__name {
          width: 300px;
          margin: auto 5px;
        }
        &__products {
          width: 500px;
          margin: auto 5px;
        }
        &__quantity {
          width: 100px;
          margin: auto 5px;
        }
        &__order {
          width: 100px;
          margin: auto 5px;
        }
        &__id {
          width: 100px;
          margin: auto 5px;
        }
        &__delete {
          width: 100px;
          margin: auto 5px;
        }
      }
    }
    &__button {
      width: 100px;
      td {
        text-align: left;
        button {
          width: 100px;
          height: 40px;
          border-radius: 5px;
          background-color: #f5f5f5;
          border: 1px solid #e0e0e0;

          &:hover {
            background-color: #e0e0e0;
          }
        }
      }
    }
  }
}
