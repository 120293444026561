.clientListFieldsValueComponent {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 15px;
  &__checkbox {
    width: 20px;
  }
  &__code {
    width: 100px;
  }
  &__fullName {
    width: 200px;
  }
  &__email {
    width: 200px;
  }
  &__phone {
    width: 100px;
  }
  &__active {
    width: 80px;
  }
}
