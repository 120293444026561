.productListFieldsValueComponent {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 15px;
  text-align: center;
  &__checkbox {
    width: 20px;
  }
  &__code {
    width: 100px;
  }
  &__name {
    width: 200px;
  }
  &__description {
    width: 300px;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &__collection {
    width: 200px;
  }

  &__sub-category {
    width: 150px;
  }
  &__state {
    width: 100px;
  }
  &__image {
    width: 80px;
    // overflow: hidden;
    height: 52px;
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }
}
