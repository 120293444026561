.modifierListFieldsValueComponent {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 15px;
  &__checkbox {
    width: 20px;
  }
  &__name {
    width: 250px;
  }
  &__amount {
    width: 50px;
  }
  &__state {
    width: 100px;
  }
}
