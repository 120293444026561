.selectOptionsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  p {
    margin: 0 20px;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    min-width: 100px;
    display: flex;
    word-wrap: break-word;
    margin-left: 10px;
    width: 120px;
  }
  &__select {
    width: 200px;
    &__textarea {
      margin: 0 auto;
      border-radius: 14px;
      border: 1px solid #ebeef2;
      padding: 11px 15px;
      outline: none;
      color: hsl(0, 0%, 20%);
      font-family: "Nunito", serif;
      font-size: 16px;
      width: calc(100% - 30px);
      min-width: 50%;
      height: 100px;
      max-height: 100px;
      min-height: 100px;
    }
    &__input {
      margin: 0 auto;
      border-radius: 14px;
      border: 1px solid #ebeef2;
      padding: 11px 15px;
      outline: none;
      color: hsl(0, 0%, 20%);
      font-family: "Nunito", serif;
      font-size: 16px;
      width: calc(100% - 30px);
    }
    &__input:focus {
      border: 2px solid #4d99fd;
    }
    &__input:hover {
      border: 1px solid #b3b3b3;
    }
    &__inputDate {
      margin: 0 auto;
      border-radius: 14px;
      border: 1px solid #ebeef2;
      padding: 11px 15px;
      outline: none;
      color: hsl(0, 0%, 20%);
      font-family: "Nunito", serif;
      font-size: 16px;
      width: calc(100% - 30px);
      cursor: pointer;
    }
    &__inputHour {
      margin: 0 auto;
      border-radius: 14px;
      border: 1px solid #ebeef2;
      padding: 11px 15px;
      outline: none;
      color: hsl(0, 0%, 20%);
      font-family: "Nunito", serif;
      font-size: 16px;
      width: calc(100% - 30px);
      cursor: pointer;
    }
    &__image {
      margin: 0 auto;
      border-radius: 14px;
      border: 1px solid #ebeef2;
      padding: 11px 15px;
      outline: none;
      color: hsl(0, 0%, 20%);
      font-family: "Nunito", serif;
      font-size: 16px;
      &__link {
        word-wrap: break-word;
        padding: 4px 0;

        a {
          color: blue;
          width: 100%;
        }
      }
      &__input {
        display: flex;
        flex-wrap: wrap;
        padding: 4px 0;
        gap: 5px;
      }
    }
    &__image-edit {
      margin: 0 auto;
      border-radius: 14px;
      border: 1px solid #ebeef2;
      padding: 11px 15px;
      outline: none;
      color: hsl(0, 0%, 20%);
      font-family: "Nunito", serif;
      font-size: 16px;
      &__link {
        word-wrap: break-word;
        padding: 4px 0;
        display: flex;
        a {
          // width: 300px;
          white-space: wrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          width: 100%;
          span {
            word-wrap: break-word;
            text-decoration: underline;
            color: blue;
          }
        }
      }
      &__input {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 4px 0;
        gap: 5px;
        &__container {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          justify-content: space-between;
          label {
            flex: 1;
          }
          // input {
          //   // flex: 1;
          // }
        }
        &__img {
          border-radius: 20px;
          width: 100px;
          height: 100px;
          overflow: hidden;
          box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    &__multiple {
      margin: auto;
    }

    @include breakpoints(md) {
      &__multiple.left {
        margin-left: -100px;
      }
    }
  }
  @include breakpoints(sm) {
    p {
      width: 120px;
    }
    &__select {
      min-width: 200px;

      width: 300px;
      margin-right: 30%;
      &__input {
        overflow-x: hidden;
        width: calc(100% - 30px);
      }
    }
  }
  @include breakpoints(md) {
    p {
      width: 200px;
    }
    &__select {
      width: 300px;
      margin-right: 30%;
      &__input {
        overflow-x: hidden;
      }
    }
  }
  @include breakpoints(lg) {
    &__select {
      width: 500px;
      margin-right: 30%;
      &__image-edit {
        &__input {
          overflow: hidden;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          &__container {
            width: 300px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            flex: 1;
            row-gap: 10px;
            label {
              width: 100%;
              flex: 1;
            }
            input {
              width: 100%;
              cursor: pointer;
            }
          }
          &__img {
            width: 100px;
          }
        }
      }
    }
  }
}

.css-3w2yfm-ValueContainer, .css-1fdsijx-ValueContainer {
  flex-wrap: wrap;
  cursor: pointer;
}
