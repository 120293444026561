* {
  margin: 0 auto;
  color: $primaryFontColor;
  font-family: $font-primary;
}

body {
  max-width: 100rem;
}

thead {
  display: table-header-group;
  vertical-align: middle;
}

tbody {
  display: table-row-group;
  vertical-align: middle;
}

a {
  text-decoration: none;
}

aside div {
  margin: unset;
}

.map {
  width: 100%;
  height: 100%;
  &__title {
    text-align: center;
    text-transform: uppercase;
    width: 240px;
    border: none;
    padding: 5px;
  }
  &__buttons {
    margin: 20px 0 0 0;
    display: flex;
    button {
      background-color: $colorButton;
      cursor: pointer;
      border: none;
      border-radius: 14px;
      column-gap: 20px;
      width: 120px;
      height: 51px;
      font-family: "Nunito";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      transition: all 0.3s ease-in-out;
      transform: scale(1);
    }
  }
}
