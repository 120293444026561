.noteSearchContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  gap: 5px;
  &__search {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
  }
  &__filters {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }
  @include breakpoints(sm) {
    &__search {
      flex-direction: row;
      column-gap: 10px;
      &__input {
        width: 100%;
      }
    }
    &__filters {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      &__item {
        margin: 0 20px;
      }
    }
  }
}

