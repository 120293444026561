.productListFieldsKeyComponent {
  height: 40px;
  background-color: $backgroundColor;
  display: flex;
  align-items: center;
  font-size: 15px;
  &__checkbox {
    width: 20px;
  }
  &__code {
    width: 100px;
  }
  &__name {
    width: 200px;
  }
  &__description {
    width: 300px;
  }
  &__collection {
    width: 200px;
  }
  &__sub-category {
    width: 150px;
  }
  &__state {
    width: 100px;
  }
  &__image {
    width: 80px;
  }
}
