.selectTimeMUIComponent {
  background-color: white;
  z-index: 11111;
  &__picker {
    // background-color: green;

    &__text {
      background-color: yellow;
    }
  }
}
