.buttonPushComponent {
  display: flex;
  align-items: center;
  button {
    background-color: $colorButton;
    border: none;
    width: 146px;
    height: 48px;
    border-radius: 14px;
    cursor: pointer;
    color: #ffffff;
    font-size: 15px;
  }
}
