.collectionListContainer {
    margin: 20px;
    background-color: #ffffff;
    border-radius: 20px;
    &__search {
      padding-top: 20px;
    }
    &__table {
      width: 100%;
      overflow-x: auto;
    }
  }