.login-layout {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0;
  width: 100%;
  height: 100%;
  &__container {
    width: 100%;
    min-width: 375px;
    max-width: 400px;
    box-shadow: 0px 4px 14px -3px rgba(0, 0, 0, 0.75);
    margin: 0;

    &__welcome {
      margin: 0;
      z-index: -1;
      div {
        height: 280px;
        img {
          position: relative;
          object-fit: cover;
          margin: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
        }
      }
    }
    &__form {
      background-color: $colorWhite;
      height: 515px;
      margin-top: -16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 16px 16px 0px 0px;
      z-index: 1;
      overflow: hidden;
      &__title {
        font-family: $font-primary;
        padding-bottom: 50px;
        font-size: 1.5rem;
        font-weight: 800;
      }
    }
  }
  @include breakpoints(md) {
    width: 100%;
    height: 100%;
    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      max-width: 100vw;
      height: 100vh;

      &__welcome {
        min-width: 50vw;
        height: 100vh;
        div {
          height: 100vh;
          width: 50vw;
        }
      }
      &__form {
        min-width: 50vw;
        height: 100vh;
        &__title {
          font-family: $font-primary;
          margin-bottom: -50px;
        }
      }
    }
  }
  @include breakpoints(lg) {
    width: 100%;
    height: 100%;
    &__container {
      width: 100%;
      height: 100%;
      &__welcome {
        div {
          height: 100vh;
          width: 50vw;
        }
      }
      &__form {
        width: 50vw;
        height: 100vh;
        &__title {
          font-family: $font-primary;
          margin-bottom: 0px;
        }
      }
    }
  }
  @include breakpoints(xl) {
    &__container {
      &__form {
        font-size: 25px;
        width: 50vw;
        height: 100vh;
        &__title {
          font-size: 2rem;
          font-family: $font-primary;
          margin-bottom: 0px;
        }
      }
    }  
  }
}

