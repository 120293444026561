.storeSheduleDetailTableBodyContainer {
  input {
    outline: none;
    width: 70%;
    text-align: center;
    border-radius: 14px;
    border: 1px solid #ebeef2;
    padding: 11px 5px;
    outline: none;
    color: hsl(0, 0%, 20%);
    font-family: "Nunito", serif;
    font-size: 14px;
  }
  height: 50px;
  text-align: center;
  width: 100%;
  &__day {
    min-width: 200px;
    max-width: 200px;
  }
  &__startTime {
    min-width: 250px;
    max-width: 250px;
  }
  &__endTime {
    min-width: 250px;
    max-width: 250px;
  }
  &__name {
    text-align: center;
    min-width: 300px;
    max-width: 300px;
  }
  &__image {
    min-width: 500px;
    max-width: 500px;
    &__link {
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      a {
        color: blue;
        width: 100%;
      }
    }
  }
  &__order {
    text-align: center;
    min-width: 200px;
    max-width: 200px;
    input {
      text-align: center;
    }
  }
  &__active {
    text-align: center;
    min-width: 100px;
    max-width: 100px;
  }
  &__delete {
    text-align: center;
    min-width: 100px;
    max-width: 100px;
  }
  &__modality {
    text-align: center;
    min-width: 250px;
    max-width: 250px;
  }
  &__time {
    text-align: center;
    display: flex;
    padding-top: 15px;
    text-align: center;
    min-width: 80px;
    max-width: 80px;
    margin-left: -15px;
    padding-right: 10px;
    z-index: -1;
  }
}

.span-open {
  // position: relative;
}

.McselectTimeMUIComponent__picker {
  background-color: red;
  z-index: 1;
}
.MuiPickerStaticWrapper-content {
  background-color: red;
  z-index: 1;
}

.css-epd502 {
  // background-color: red;
}
.MuiPickersToolbar-root {
  // background-color: yellow;
}
.MuiClock-squareMask {
  //  background-color: red transparent;
  //  z-index: 100;
}
