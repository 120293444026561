.under-construction {
  text-align: center;
  padding: 50px;

  h1 {
    font-size: 48px;
    margin-bottom: 20px;
  }

  img {
    max-width: 100%;
    margin-bottom: 20px;
  }

  p {
    font-size: 24px;
  }
}
