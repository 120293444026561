.productDetailMainContainer {
  padding: 15px 15px 10px;
  width: 90%;
  background-color: #ffffff;
  border-radius: 20px;
  margin-bottom: 30px;
  &__information-second {
    &__title {
      font-weight: 600;
      padding: 10px;
      background-color: $backgroundColorTitle;
      border-radius: 12px;
    }
    &__container {
      &__item {
        &__title {
          margin-left: 20px;
          padding: 10px;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
        }
        &__description {
          select {
            margin-left: 20px;
            width: 90%;
            padding: 10px;
            border: 1px solid #ebeef2;
            border-radius: 14px;
            outline: none;
          }
        }
      }
    }
  }
}
