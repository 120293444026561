.noteRetrieveInformationContainer {
  padding: 15px 15px 10px;
  width: 90%;
  background-color: #ffffff;
  border-radius: 20px;
  &__title {
    font-weight: 600;
    padding: 10px;
    background-color: $backgroundColorTitle;
    border-radius: 12px;
  }
}
