.orderDetailHeaderContainer {
  position: relative;
  display: flex;
  padding: 30px;
  &__icon {
    position: absolute;
    cursor: pointer;
  }
  &__container {
    display: flex;
    flex-direction: column;
    &__title {
      h5 {
        font-size: 20px;
      }
    }
    &__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      column-gap: 30px;
    }
  }
  @include breakpoints(md) {
    &__container {
      display: flex;
      flex-direction: column;
      &__title {
        h5 {
          font-size: 20px;
        }
      }
      &__text {
        flex-direction: row;
        column-gap: 30px;
      }
    }
  }
}
