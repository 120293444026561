.paymentListFieldsKeyComponent {
  height: 40px;
  width: 100%;
  background-color: $backgroundColor;
  display: flex;
  align-items: center;
  font-size: 15px;
  &__code {
    width: 100px;
  }
  &__order {
    width: 100px;
  }
  &__method {
    width: 150px;
  }
  &__state {
    width: 110px;
  }
  &__date {
    width: 300px;
  }
}
