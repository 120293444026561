.productDetailFieldsKeyComponent {
  margin-top: 10px;
  height: 40px;
  background-color: $backgroundColor;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  &__name {
    width: 300px;
    margin: auto 5px;
  }
  &__order {
    width: 200px;
    margin: auto 5px;
  }
  &__active {
    width: 120px;
    margin: auto 5px;
  }
  &__mandatory {
    width: 150px;
    margin: auto 5px;
  }
  &__multiple {
    width: 150px;
    margin: auto 5px;
  }
  &__amount {
    width: 80px;
    margin: auto 5px;
  }
  &__delete {
    width: 100px;
    margin: auto 5px;
  }
}
