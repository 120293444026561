.productDetailModifierContainer {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  outline: none;
  &__select {
    height: 40px;
    flex-grow: 2;
    max-width: 670px;
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }
}
