.clientRetrieveInformationContainer {
  margin: 20px;
  background-color: $colorWhite;
  border-radius: 16px;
  &__background {
    padding: 20px;
    img {
      width: 100%;
      object-fit: cover;
      border-radius: 12px;
      height: 200px;
      @include breakpoints(md) {
        height: 140px;
      }
    }
  }
  &__information {
    padding: 0 20px;
    @include breakpoints(md) {
      display: block;
    }
    @include breakpoints(lg) {
      display: flex;
      max-height: 180px;
    }
    &__keyValue {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      max-width: 300px;
      position: relative;
      bottom: 40px;
      @include breakpoints(sm) {
        max-width: 400px;
      }
      @include breakpoints(md) {
        max-width: 500px;
      }
      @include breakpoints(lg) {
        max-width: 360px;
        position: inherit;
      }
      &__item {
        width: 200px;
        max-width: 200px;
        display: flex;
        div {
        margin: 10px 20px;
        }
        svg {
            margin: 12px 0;
          }
        @include breakpoints(sm) {
          width: 180px;
          margin: 10px;
          max-width: 180px;
        }
        @include breakpoints(md) {
          max-width: 220px;
        }
        @include breakpoints(lg) {
          margin: 10px 0;
          width: 150px;
          display: flex;
          max-width: 150px;
          div {
            margin: auto;
            }
          svg {
            display: none;
          }
        }
        @include breakpoints(xl) {
          max-width: 180px;
        }
      }
    }
    &__title {
      min-width: 200px;
      text-align: center;
      position: relative;
      bottom: 80px;
      @include breakpoints(lg) {
        position: inherit;
        text-align: left;
        min-width: 330px;
      }
      @include breakpoints(xl) {
        min-width: 400px;
      }
      &__names {
        font-size: 20px;
      }
      &__code {
        padding-top: 10px;
      }
    }
    &__image {
      min-width: 150px;
      min-height: 150px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      position: relative;
      bottom: 80px;
      @include breakpoints(lg) {
        margin: 0;
      }
      @include breakpoints(xl) {
        min-width: 180px;
        min-height: 180px;
        margin: 0 40px;
      }
      img {
        width: 60%;
        border-radius: 50%;
        border: solid 8px #ffffff;
        background-color: #ffffff;
        object-fit: cover;
        @include breakpoints(sm) {
          width: 60%;
        }
        @include breakpoints(lg) {
          width: 80%;
        }
        @include breakpoints(xl) {
          width: 100%;
        }
      }
    }
  }
}
