.clientCreateInformationContainer {
  padding: 15px 15px 10px;
  width: 90%;
  background-color: #ffffff;
  border-radius: 20px;
  &__title {
    font-weight: 600;
    padding: 10px;
    background-color: $backgroundColorTitle;
    border-radius: 12px;
  }
  &__container {
    &__type {
      display: flex;
      padding: 20px 0;
      p {
        margin: 0 20px;
        padding: 10px;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      }
      select {
        margin-top: 10px;
        font-size: 16px;
        border: 1px solid #ebeef2;
        border-radius: 14px;
        transition: all 0.3s ease-out;
        overflow: hidden;
        width: 100%;
        height: 45px;
        border-radius: 14px;
        outline: none;
        padding: 3px 5px;
      }
    }
  }
}
