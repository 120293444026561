.codeBlockComponent {
  &__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    row-gap: 20px;

    button {
      padding: 10px 20px;
      border-radius: 4px;
      border: none;
      background-color: #007bff;
      color: white;
      font-size: 16px;
      transition: all 0.2s ease-in-out;
    }
    button:hover {
      cursor: pointer;
      background-color: #0069d9;
    }
    button.copied {
      background-color: #28a745;
    }
    button.copied:hover {
      background-color: #218838;
    }
  }
}
