.storeListFieldsValueComponent {
  // width: 100%;
  width: calc(100% - 20px);
  height: 60px;
  display: flex;
  align-items: center;
  font-size: 15px;
  text-align: center;
  &__checkbox {
    width: 20px;
  }
  &__slug {
    width: 100px;
  }
  &__active {
    width: 80px;
  }
  &__name {
    width: 120px;
  }
  &__icon {
    cursor: pointer;
    width: 90px;
  }
  &__address {
    width: 400px;
  }
  &__description {
    width: 300px;
  }
  &__image {
    width: 120px;
    height: 50px;
    img {
      height: 100%;
      width: 80px;
      object-fit: cover;
    }
  }
}
