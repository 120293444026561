.collectionListFieldsValueComponent {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 15px;
  text-align: center;
  &__checkbox {
    width: 20px;
  }
  &__name {
    width: 150px;
  }
  &__description {
    width: 200px;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  &__time {
    width: 120px;
  }
  &__state {
    width: 100px;
  }
}
