.inputSearchComponent {
  display: flex;
  align-items: center;
  border-radius: 14px;
  border: 2px solid $backgroundColor;
  height: 44px;
  width: 100%;
  svg {
    width: 20px;
    height: 20px;
    fill: $primaryFontColor;
    margin-left: 10px;
    margin-right: 0;
  }
  input {
    border: none;
    width: calc(100% - 50px);
    height: 30px;
    outline: none;
  }
}
