// @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500;700&family=Nunito:wght@300;400;500;700&display=swap");

$font-primary: "Nunito", serif;

$colorBlack: #000;
$colorBlackLight: #0e0101;
$colorBlack100: #c9c9c9;
$colorBlack200: #919191;
$colorBlack300: #525252;
$colorBlack400: #333333;

$colorWhite: #fff;
$colorWhite200: #f5f5f5;
$colorWhite300: #ededed;
$colorWhite400: #e3e3e3;
$colorWhite500: #d9d9d9;

$colorButton: #2d99ff;
$backgroundColor: #f1f3f7;
$backgroundColorTitle: #f5f7fa;

$primaryFontColor: #1a1926;

@mixin breakpoints($point) {
  @if $point == sm {
    @media (min-width: 576px) {
      @content;
    }
  }
  @if $point == md {
    @media (min-width: 768px) {
      @content;
    }
  }
  @if $point == lg {
    @media (min-width: 992px) {
      @content;
    }
  }
  @if $point == xl {
    @media (min-width: 1200px) {
      @content;
    }
  }
  @if $point == xxl {
    @media (min-width: 1400px) {
      @content;
    }
  }
}
