.orderCreateMainContainer {
  padding: 15px 15px 10px;
  width: 90%;
  background-color: #ffffff;
  border-radius: 20px;
  margin-bottom: 30px;
  padding-bottom: 200px;
  &__input-radio {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 100%;
    margin: 0 auto 0 20px;
    padding: 20px 10px;
    gap: 20px;
    max-width: 60%;
    &__item {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      gap: 15px;
      margin: 0 auto 0 0;
    }
  }
  &__direction {
    position: relative;
    margin-bottom: 40px;
    &__container {
      &__text {
        display: grid;
        place-items: center;
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        color: #000000;
        margin-top: -17px;
        margin-bottom: 10px;
      }
      &__button {
        background-color: red;
        display: grid;
        place-items: center;
        width: 100px;
        background-color: $colorButton;
        padding: 10px;
        border-radius: 14px;
        color: #ffffff;
      }
    }
  }
  &__container-add {
    &__button {
      padding: 10px;
      width: 100px;
      text-align: center;
      border-radius: 14px;
      background-color: $colorButton;
      cursor: pointer;
      border: none;
      color: #ffffff;
    }
  }
  &__map {
    height: 65vh;
    width: 90%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  @include breakpoints(sm) {
    &__input-radio {
      max-width: 47%;
    }
    &__direction {
      position: relative;
      margin-bottom: 40px;
      &__container {
        &__button {
          left: 0;
        }
      }
    }
    &__map {
      width: 70%;
      margin-right: 50%;
    }
    &__container-add {
      margin-left: -20%;
    }
  }
  @include breakpoints(md) {
    &__input-radio {
      max-width: 57%;
    }
    &__map {
      height: 75vh;
      width: 60%;
      margin-right: 33%;
    }
  }
  @include breakpoints(lg) {
    &__input-radio {
      max-width: 44%;
    }
  }
}
