.keyValueInputComponent {
  margin-left: 20px;
  &__title {
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
  &__description {
    padding: 10px;
    border: 1px solid #ebeef2;
    border-radius: 14px;
    transition: all 0.3s ease-out;
    &__file {
      padding: 20px;
      margin: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: 1px dashed #c6c8cc;
      border-radius: 14px;
      input[type="file"] {
        padding: 10px;
        color: red;
        border: none;
        width: 100%;
        outline: none;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
      }
      #file-upload-button {
        color: blue;
      }
      span {
        color: rgba(45, 153, 255, 1);
      }
    }
    input {
      border: none;
      width: 100%;
      outline: none;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
    }
    textarea {
      border: none;
      outline: none;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      width: 100%;
      height: 100%;
    }
  }
  &__description:hover {
    border: 1.5px solid rgba(45, 153, 255, 1);
  }
  &__text {
    padding: 0 20px;
    overflow: hidden;
    font-size: 15px;
  }
}
