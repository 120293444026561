.paymentRetrieveInformationContainer {
  padding: 10px;

  &__title {
    font-weight: 600;
    padding: 10px 16px;
    background-color: $backgroundColor;
    border-radius: 12px;
  }
  &__fields {
    padding: 0 20px;
    p {
      margin: 0;
    }
  }
}
