.selectCommentComponent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  width: 250px;
  padding-bottom: 10px;
  &__text {
    padding-bottom: 10px;
  }
  &__input {
    min-width: 200px;
    display: flex;
    align-items: center;
    // justify-content: center;
    justify-content: space-between;
    border-radius: 14px;
    border: 2px solid #ebeef2;
    padding: 11px 15px;
    outline: none;
    color: hsl(0, 0%, 5%);
    font-size: 16px;
    margin: auto;
    height: 50px;
  }
  @include breakpoints(sm) {
    width: 300px;
    &__input {
      min-width: 250px;
    }
  }
  @include breakpoints(md) {
    width: 350px;
    &__input {
      min-width: 300px;
    }
  }
  @include breakpoints(lg) {
    width: 400px;
    &__input {
      min-width: 350px;
    }
  }
  @include breakpoints(xl) {
    width: 450px;
    &__input {
      min-width: 400px;
    }
  }
}
