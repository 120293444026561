.orderListGroupPresentationContainer {
  display: flex;
  justify-content: center;
  justify-items: center;
  margin-bottom: 20px;
  flex-direction: column;
  gap: 20px;

  &__groups {
    &__container {
      display: flex;
      align-items: center;
      width: 250px;
      column-gap: 10px;
      &__title {
        cursor: pointer;
        min-width: 200px;
        display: flex;
        align-items: center;
        // justify-content: center;
        justify-content: space-between;
        border-radius: 14px;
        border: 1px solid #ebeef2;
        padding: 11px 15px;
        outline: none;
        color: hsl(0, 0%, 20%);
        font-size: 16px;
        margin: auto;
      }
      &__span {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $colorButton;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        span {
          color: white;
        }
      }
    }
    &__body {
      justify-content: left;
      padding: 15px 0;
      display: flex;
      width: 100%;
      &__left {
        width: 80%;
        // margin-right: 22%;
        label {
          padding: 20px;
        }
      }
      &__right {
        label {
        }
      }
    }
  }

  @include breakpoints(sm) {
    &__groups {
      &__container {
        width: 280px;
      }
    }
  }
  @include breakpoints(md) {
    &__groups {
      &__container {
        width: 100%;
        margin-right: 30%;
        &__title {
          width: 300px;
        }
      }
    }
  }
}
