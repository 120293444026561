.sectionTableContainer {
  textarea {
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    height: 60px;
    min-height: 60px;
    max-height: 60px;

    padding: 10px;
    border: 1px solid #ebeef2;
    border-radius: 14px;
    transition: all 0.3s ease-out;
  }
  input {
    padding: 10px;
    border: 1px solid #ebeef2;
    border-radius: 14px;
    transition: all 0.3s ease-out;
    text-align: center;
  }
  td {
    text-align: center;
    min-width: 150px;
    svg {
      cursor: pointer;
      fill: red;
    }
  }
}
