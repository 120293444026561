.selectCountComponent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  width: 80%;
  gap: 20px;
  margin-left: 50px;
  &__text {
    width: 200px;
  }
  &__value {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    &__rest,
    &__sum {
      cursor: pointer;
      line-height: 1.8;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      color: white;
    }
    &__rest {
      background-color: rgb(165, 161, 161);
    }
    &__sum {
      background-color: #73b6dd;
    }
  }
  @include breakpoints(sm) {
    margin-left: 29%;
    width: 40%;
    gap: 70px;
    &__text {
      width: 150px;
    }
    &__value {
      width: 150px;
      margin-left: -100px;
    }
  }
}
