.storeDetailTableHeadContainer {
  &__row {
    width: 100%;
    height: 60px;
    background-color: $backgroundColor;
    display: flex;
    align-items: center;
    font-size: 15px;
    text-align: center;
    padding-left: 4px;
    &__name {
      width: 300px;
    }
    &__modality {
      width: 250px;
    }
    &__day {
      width: 200px;
    }
    &__openingTime {
      width: 330px;
    }
    &__closingTime {
      width: 330px;
    }
    &__photo {
      width: 500px;
    }
    &__order {
      width: 200px;
    }
    &__active {
      width: 100px;
    }
    &__delete {
      width: 100px;
    }
  }
}
