.clientRetrieveOrderContainer {
  margin: 20px;
  background-color: $colorWhite;
  border-radius: 16px;
  &__title {
    padding: 20px;
    h2 {
      color: $colorButton;
    }
  }
  &__line {
    &__one {
      margin: 0 0 0 20px;
      width: 130px;
      height: 5px;
      border-radius: 4px 4px 0 0;
      background-color: $colorButton;
      border: none;
    }
    &__two {
      background-color: $backgroundColor;
      height: 2px;
      border: none;
    }
  }
  &__table {
    width: 100%;
    overflow-x: auto;
  }
}
