.homeDashboardContainer {
  margin: 20px;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;
  min-width: 250px;
  &__container-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    &__title {
      flex: 1;
      font-size: 20px;
      padding-left: 5px;
    }
    &__button {
      display: flex;
      align-items: center;
      button {
        background-color: $colorButton;
        border: none;
        width: 146px;
        height: 48px;
        border-radius: 14px;
        cursor: pointer;
        color: #ffffff;
        font-size: 15px;
      }
    }
  }
  &__container-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    &__left {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;
      flex: 1;
      text-align: left;
      &__filter.dashboard-check {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 5px;
      }
    }
    &__right {
      flex: 1;
      text-align: center;
      // opacity: 0;
    }
  }
  &__container-table {
    display: flex;
    align-items: center;
    justify-content: left;
    text-overflow: ellipsis;
    overflow-x: scroll;
    position: relative;
    height: 350px;
  }
  &__container-table.reportClient {
    // align-items: normal;
    position: relative;
    flex-direction: column;
    overflow: scroll;
    // height: 350px;
    height: calc(100vh - 300px);
  }
  &__container-grafic {
    display: flex;
  }
  &__loading {
    position: relative;
    margin-top: 5%;
  }
}
