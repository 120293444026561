.paginationPreviusComponent {
  display: flex;
  align-items: center;
  width: 50px;
  height: 50px;

  button {
    border: none;
    cursor: pointer;
    svg {
      width: 20px;
      height: 15px;
    }
  }
}
