.modalContainer {
  background-color: white;
  width: 500px;
  min-width: 350px;
  min-height: 100px;
  position: relative;
  border-radius: 16px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  h3 {
    margin: 10px 0;
  }
  &__text {
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 20px 2px;
  }
}
