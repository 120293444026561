.storeListFieldsKeyComponent {
  width: calc(100% - 20px);
  height: 40px;
  background-color: $backgroundColor;
  display: flex;
  align-items: center;
  font-size: 15px;
  &__checkbox {
    width: 20px;
  }
  &__name {
    width: 100px;
  }
  &__active {
    width: 80px;
  }
  &__delete {
    width: 90px;
  }
  &__slug {
    width: 100px;
  }
  &__address {
    width: 400px;
  }
  &__description {
    width: 300px;
  }
  &__image {
    width: 80px;
    img {
      width: 100%;
    }
  }
}
