.orderDetailSummaryContainer {
  padding: 0 25px;
  &__title {
    padding: 10px 16px;
    background-color: #f5f7fa;
    border-radius: 12px;
    font-weight: 600;
  }
  &__container {
    width: 92%;
    font-size: 14px;
    &__description {
      display: flex;
      padding: 8px 0;
      &__price {
        text-align: right;
      }
      &__text {
        width: 100%;
      }
    }
  }
}
