.filterComponent {
  select {
    border-radius: 14px;
    height: 48px;
    width: 123px;
    font-size: 15px;
    text-align: center;
    border-color: $backgroundColor;
  }
}
