.modal-perfil {
  position: absolute;
  top: 62px;
  right: 10px;
  background-color: white;
  width: 300px;
  box-shadow: 0px 16px 80px rgba(4, 15, 21, 0.15),
    0px 40px 20px -30px rgba(4, 15, 21, 0.1);
  border-radius: 14px;
  padding-top: 8px;

  &__content {
    position: relative;
    background: url("https://i.postimg.cc/65F8Sd4S/bg-perfil-min.png");
    background-size: 100%;
    width: 284px;
    height: 64px;
    display: grid;
    grid-template-columns: 60px 110px 1fr;
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
    border-radius: 17px;
    &__icon {
      display: flex;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid white;
      }
    }
    &__name {
      margin-left: 8px;
      p {
        font-family: $font-primary;
        font-weight: 300;
        font-size: 15px;
        line-height: 20px;
        color: #ffffff;
      }
    }
  }
  &__separate {
    width: 100%;
    margin: 8px 0;
    border: none;
    height: 1px;
    background-color: #e5e8ea;
    margin-bottom: 10px;
  }
  &__item {
    display: grid;
    grid-template-columns: 60px 1fr 194px;
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    &__text {
      display: flex;
      padding-bottom: 10px;
      p {
        width: 100%;
        font-weight: bold;
        color: #637085;
        font-family: $font-primary;
        font-size: 15px;
        line-height: 20px;
        color: rgba(40, 38, 59, 1);
      }
    }
  }
}

.hidden-modal-perfil {
  display: none;
}
