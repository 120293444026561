.collectionListFieldsKeyComponent {
  height: 40px;
  display: flex;
  align-items: center;
  background-color: $backgroundColor;
  font-size: 15px;
  &__checkbox {
    width: 20px;
  }
  &__name {
    width: 150px;
  }
  &__description {
    width: 200px;
  }
  &__time {
    width: 120px;
  }
  &__state {
    width: 100px;
  }
}
