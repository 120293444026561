.buttonSearchComponent {
  button {
    background-color: $colorButton;
    border: none;
    border-radius: 14px;
    height: 48px;
    width: 80px;
    color: $colorWhite;
    cursor: pointer;
  }
}
