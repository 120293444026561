.productDetailInformationContainer {
  width: 100%;
  overflow-x: scroll;
  &__table {
    padding-top: 20px;
    width: 100%;
    overflow-x: scroll;
    thead {
      width: 100vw;
      background-color: $backgroundColorTitle;
      tr {
        padding: 20px;
        display: flex;
        align-items: center;
        th {
          text-align: center;
        }
      }
    }
    tbody {
      tr {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        td {
          width: 100%;
          text-align: center;
          textarea {
            border: 1px solid #ebeef2;
            border-radius: 14px;
            padding: 10px;
            max-height: 80px;
            min-height: 80px;
            max-width: 180px;
            min-width: 180px;
          }
          input {
            border: 1px solid #ebeef2;
            border-radius: 14px;
            text-align: center;
            padding: 10px;
            width: 100px;
          }
        }
      }
    }

    &__question {
      text-align: center;
      min-width: 202px;
    }
    &__order {
      text-align: center;
      min-width: 150px;
      input {
        width: 100%;
        text-align: center;
      }
    }
    &__active {
      text-align: center;
      min-width: 150px;
    }
    &__delete {
      text-align: center;
      min-width: 150px;
      svg {
        cursor: pointer;
        fill: red;
      }
    }
  }
  &__answers {
    margin-left: 50px;
    width: 100%;

    // overflow-x: scroll;
    &__title {
      display: flex;
      background-color: $backgroundColorTitle;
      padding: 20px;
      margin-bottom: 10px;
      text-align: center;
      color: black;
      font-weight: 900;
      // width: 100vw;
      min-width: 600px;
      &__answers {
        min-width: 400px;
      }
      &__order {
        min-width: 100px;
      }
      &__active {
        min-width: 100px;
      }
      &__delete {
        min-width: 100px;
      }
    }
    &__quill {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      min-width: 550px;
      &__component {
        // margin-top: 200px;
        margin-left: 80px;
        width: 300px;
        max-height: 350px;
        overflow: scroll;
      }
      &__order {
        width: 100px;

        input {
          margin-left: 50px;
          border: 1px solid #ebeef2;
          border-radius: 14px;
          text-align: center;
          padding: 10px;
          width: 100%;
        }
      }
      &__active {
        width: 100px;
        input {
          margin-left: 110px;
        }
      }
      &__delete {
        width: 100px;
        margin-right: 10px;
        svg {
          fill: red;
        }
      }
    }
  }
  @include breakpoints(sm) {
    width: 100%;
    overflow-x: auto;
    &__table {
      thead {
        tr {
          width: 100%;
          th {
            width: 200px;
          }
        }
      }
      tbody {
        tr {
          width: 100%;
          td {
            width: 200px;
            textarea {
            }
            input {
            }
          }
        }
      }
    }
    &__answers {
      // overflow-x: scroll;
      // margin-left: 100px;
      &__title {
        display: flex;
        background-color: $backgroundColorTitle;
        padding: 20px;
        // margin-bottom: 10px;
        text-align: center;
        color: black;
        font-weight: 900;
        &__answers {
          min-width: 650px;
        }
        &__order {
          max-width: 80px;
        }
        &__active {
          min-width: 100px;
        }
      }
      &__quill {
        display: flex;
        justify-content: center;
        align-items: center;
        &__component {
          margin-left: 0px;
          margin: auto;
          min-width: 650px;
        }
        &__order {
          padding: 0;
          max-width: 80px;
          margin: auto;
          input {
            margin-left: 0px;
          }
        }
        &__active {
          min-width: 100px;
          margin: auto;
          input {
            margin-left: 0px;
          }
        }
      }
    }
  }
}
