.paymentRetrieveStateContainer {
  position: relative;
  display: flex;
  padding: 30px;
  &__icon {
    position: absolute;
    cursor: pointer;
  }
  &__container {
    display: flex;
    flex-direction: column;
    &__title {
      h5 {
        font-size: 20px;
      }
    }
    &__text {
      display: block;
      column-gap: 30px;
      @include breakpoints(sm) {
        display: flex;
      }
    }
  }
}
