.orderListFieldsValueComponent {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 15px;
  &__checkbox {
    width: 20px;
  }
  &__code {
    width: 110px;
  }
  &__client {
    width: 250px;
  }
  &__modality {
    width: 100px;
  }
  &__shop {
    width: 100px;
  }
  &__total {
    width: 80px;
  }
  &__state {
    width: 115px;
  }
  &__date {
    width: 300px;
  }
}

.recibida,
.confirmada,
.lista-para-retiro,
.en-camino,
.entregada,
.cancelada,
.pendiente,
.activo a,
.inactivo a,
.en-revision a {
  color: white;
  font-weight: 500;
  border-radius: 20px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.recibida {
  background-color: #3bd7ef;
}

.confirmada {
  background-color: #74f1ab;
}
.pendiente {
  background-color: #cfec4c;
}

.lista-para-retiro {
  background-color: #e6f84c;
}

.en-camino {
  background-color: #f39c12;
}

.entregada {
  background-color: #2ecc71;
}

.cancelada {
  background-color: #e74c3c;
}

.activo {
  a {
    background-color: #3af26e;
  }
}
.inactivo {
  a {
    background-color: #f1412d;
  }
}
.en-revision {
  a {
    background-color: #f1c40f;
  }
}
