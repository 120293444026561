aside {
  margin-left: -80px;
}
ul.css-ewdv3l {
  margin-left: 0px;
}

.ps-sidebar-root {
  background: rgba(26, 142, 255, 0.1);
}
.ps-submenu-content ul {
  background-color: #f1f4f9;
}

// .ps-menu-icon {
//   svg {
//     width: 18px;
//     height: 18px;
//   }
// }
// .ps-menu-icon.css-wx7wi4 {
//   svg {
//     width: 10px;
//     height: 10px;
//   }
// }

.sidebar__logo {
  position: relative;
  height: 80px;
  .css-1bdadyq {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
  }
  svg {
    width: 100%;
    height: 100%;
  }
  p {
    font-family: Comfortaa;
    font-style: bold;
    font-size: 26px;
    font-weight: 700;
  }
}
nav ul li a {
  display: flex;
  justify-content: center;
}
.css-vj11vy {
  width: 96%;
}

a.ps-menu-button.css-1bdadyq:hover {
  border-radius: 16px;

  color: #2d99ff;
  svg {
    fill: #2d99ff;
  }
  li {
    color: #2d99ff;
  }
  li:hover {
    color: #2d99ff;
  }
}

.css-ra3y5v .css-wx7wi4 {
  svg {
    stroke-width: 3px;
  }
}

a.ps-menu-button.css-ra3y5v:hover {
  margin-left: 0px;
  border-radius: 16px;
  background: rgba(26, 142, 255, 0.1);
  color: #2d99ff;
  svg {
    fill: #2d99ff;
  }
  li {
    color: black;
  }
  li:hover {
    color: #2d99ff;
  }
}

.activeLink {
  border-radius: 16px;
  background: rgba(26, 142, 255, 0.1);
  color: #2d99ff;
  svg {
    fill: #2d99ff;
  }
  li {
    color: #2d99ff;
  }
  li:hover {
    color: #2d99ff;
  }
}

.css-1bdadyq {
  margin-left: 16.4px;
}
.css-ra3y5v {
  margin-left: 9.9px;
  transition: margin 0.5s ease-out;
}

a.ps-menu-button:hover {
  background: rgba(26, 142, 255, 0.1);
}
.sidebar__logo a:hover {
  background: none;
  cursor: pointer;
}

@include breakpoints(sm) {
}
@include breakpoints(md) {
  aside {
    margin-left: 0px;
  }
  .css-1bdadyq {
    margin-left: 0px;
  }
  .css-ra3y5v {
    margin-left: -20px;
  }
}

.sidebarContainer {
  position: fixed !important;
  height: 100vh;
  &__hidden {
    display: none;
  }
  background-color: red;
  @include breakpoints(md) {
    position: relative !important;
    background-color: blue;
    &__hidden {
      display: none;
    }
  }
}

// .sidebar{
//   position: fixed;
//   background-color: #f1f4f9;
//   aside {
//     margin-left: 0px;
//   }
//   width: 29px;
//   &__container{
//     &__menu{
//       width: 29px;
//       // background-color: red;
//     }
//   }
//   @include breakpoints(md) {
//     position: relative;
//     aside {
//       margin-left: 0px;
//     }
//   }
// }
