.pageComponent {
  display: flex;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: #edeff2;
  margin: 1px;
  border-radius: 14px;
}

.activePage {
  background-color: #2d99ff !important;
  p {
    color: #ffffff;
  }
}
